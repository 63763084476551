import { Circle, Plus } from '@phosphor-icons/react'
import * as React from 'react'
import { Position } from 'store/modules/positions'
import { AddSkillsDropdown } from './add-skills-dropdown'

type RequiredSkillsEmptyStateProps = {
  addSkillsPath: string
  editable?: boolean
  position: Position
}

export const RequiredSkillsEmptyState: React.VFC<
  RequiredSkillsEmptyStateProps
> = (props) => {
  const { addSkillsPath, editable, position } = props

  return (
    <div className="flex flex-col items-center w-full">
      <div className="flex items-center justify-center mb-6">
        <div className="bg-gray-100 h-3 mr-4 rounded w-12"></div>
        {[...Array(5)].map((_, index) => (
          <Circle className="h-4 text-gray-100 w-4" key={index} weight="fill" />
        ))}
      </div>
      <p className="mb-0 text-base text-gray-600">
        {editable
          ? 'Help people in this team understand what is required of them.'
          : 'This position has no required skills.'}
      </p>
      {editable && (
        <AddSkillsDropdown
          position={position}
          addSkillsPath={addSkillsPath}
          styledTrigger
        >
          <Plus className="h-4 text-theme-40 w-4" weight="bold" /> Add Skills
        </AddSkillsDropdown>
      )}
    </div>
  )
}
