import { Input, Tooltip } from 'src/design-system'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import cn from 'classnames'
import { Select } from 'components/atoms/select'
import { SalaryDialogVm } from './salary-dialog-vm'
import { MinusCircle } from '@phosphor-icons/react'
import { SalaryFormRowState, SalaryRowVm } from './salary-row-vm'

export type SalaryRowProps = {
  salary: SalaryFormRowState
  vm: SalaryDialogVm
  onChange: (
    field: keyof SalaryFormRowState,
    value: string | number | undefined
  ) => void
  onDelete: () => void
  showDelete?: boolean
}

export const SalaryRow: React.VFC<SalaryRowProps> = observer((props) => {
  const { salary, onChange, onDelete, showDelete } = props

  const vm = React.useMemo(() => new SalaryRowVm(salary), [salary])

  const parseValue = (value: string) => {
    const number = parseFloat(value)
    return Number.isNaN(number) ? undefined : number
  }

  return (
    <tr className="items-center align-middle">
      <td className="pb-4 pr-3">
        <div className="md:w-[120px]">
          <Select
            onChange={(value: string) => onChange('currency', value)}
            options={vm.currencyCodeOptions}
            isSearchable={true}
            placeholder="Search"
            triggerClassName="min-w-full justify-between border-gray-100 border border-solid rounded p-4 h-11"
            contentClassName="w-[120px]"
            value={vm.defaultCurrency}
          />
        </div>
      </td>
      <td className="pb-4 pr-3">
        <Input
          onChange={(e) =>
            onChange('fromDollars', parseValue(e.currentTarget.value))
          }
          name="salaryFrom"
          id="salaryFrom"
          type="number"
          min={0}
          step={0.01}
          defaultValue={vm.defaultSalaryFrom}
          className="border-gray-100 text-gray-900 h-11"
        />
      </td>
      <td className="pb-4 pr-3">
        <Input
          onChange={(e) =>
            onChange('toDollars', parseValue(e.currentTarget.value))
          }
          name="salaryTo"
          id="salaryTo"
          type="number"
          min={0}
          step={0.01}
          defaultValue={vm.defaultSalaryTo}
          className="border-gray-100 text-gray-900 h-11"
        />
      </td>
      <td className={cn('pb-4', showDelete && 'pr-3')}>
        <Input
          onChange={(e) => onChange('location', e.currentTarget.value)}
          name="location"
          id="location"
          type="string"
          defaultValue={vm.defaultLocation}
          className="border-gray-100 text-gray-900 h-11"
        />
      </td>
      {showDelete && (
        <td className="pb-4">
          <Tooltip content="Remove salary">
            <MinusCircle
              aria-hidden
              className="cursor-pointer h-4 w-4 text-gray-400"
              onClick={() => onDelete()}
            />
          </Tooltip>
        </td>
      )}
    </tr>
  )
})
