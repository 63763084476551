import {
  AvatarButtonGroup,
  Button,
  GlobalModal,
  useModalContext,
  useModalProps,
} from 'src/design-system'
import { Info } from '@phosphor-icons/react'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import pluralize from 'pluralize'
import { avatarGroupProps } from 'app/packs/src/utils/user-helpers'
import { ModifiablePositionAttributes, Position } from 'store/modules/positions'
import { MOVE_POSITION_DIALOG_ID } from './utils'
import { MovePositionDialogVm } from './move-position-dialog-vm'
import { promiseToast } from '../../utils/promise-toast'
import { Select } from 'components/atoms/select'

export type MovePositionDialogProps = {
  position: Position
}

export const MovePositionDialog: React.VFC = observer(() => {
  const { position } = useModalProps<MovePositionDialogProps>(
    MOVE_POSITION_DIALOG_ID
  )

  const { closeModal } = useModalContext()

  const [errors, setErrors] = React.useState<string[]>([])

  const [formState, setFormState] = React.useState<
    Partial<ModifiablePositionAttributes>
  >({ disciplines: [] })

  const [teamId, setTeamId] = React.useState<string | null>(null)

  const movePosition = async () => {
    const previousTeamId = position.team?.id
    const result = await vm.updatePosition(formState)

    if (result.success) {
      vm.onSuccess(previousTeamId)
      closeModal()
    }

    return result
  }

  const onCancel = () => {
    resetErrors()
    closeModal()
  }

  const onSubmit = async () => {
    const newErrors = vm.newErrors(formState)
    setErrors(newErrors)
    if (newErrors.length > 0) return

    promiseToast(
      `move-position-${position.id}-dialog`,
      movePosition(),
      'Position moved',
      undefined,
      'Moving position..'
    )
  }

  const onTeamChange = (teamId: string) => {
    setFormState({ disciplines: [] })
    setTeamId(teamId)
  }

  const resetErrors = () => {
    setErrors([])
  }

  const setDisciplines = (
    disciplines: ModifiablePositionAttributes['disciplines']
  ) => {
    setFormState({ disciplines })
  }

  const vm = React.useMemo(() => {
    return new MovePositionDialogVm(position, teamId)
  }, [position, teamId])

  if (!position) return null

  return (
    <GlobalModal.Root
      className="w-[508px]"
      id={MOVE_POSITION_DIALOG_ID}
      title={position.name}
    >
      <div className="p-8">
        <h4 className="font-bold mb-5 text-gray-900 text-lg">
          Move this Position to..
        </h4>
        <h6 className="font-bold mb-2 text-gray-900 text-sm">Team</h6>
        <Select
          invalid={errors.includes('team')}
          onChange={onTeamChange}
          options={vm.teamOptions}
          triggerClassName="border-gray-200 justify-between max-w-full mb-5 px-4 py-3 w-full"
          triggerContent="Choose a Team"
          value={teamId || ''}
        />
        <h6 className="font-bold mb-2 text-gray-900 text-sm">Track</h6>
        <Select
          invalid={errors.includes('disciplines')}
          onChange={(disciplineId: string) => setDisciplines([disciplineId])}
          options={vm.trackOptions}
          triggerClassName="border-gray-200 justify-between max-w-full mb-5 px-4 py-3 w-full"
          triggerContent="Choose a track"
          value={formState.disciplines?.[0] || ''}
        />
        <div className="flex gap-3 items-center mb-4">
          <Info className="h4 text-yellow-700 w-4" weight="bold" />
          <p className="text-gray-900 text-sm">
            All skills, comments and position history will be moved.
          </p>
        </div>
        <div className="flex justify-end gap-2">
          <Button onClick={onCancel} type="button" variant="outline">
            Cancel
          </Button>
          <Button onClick={onSubmit} type="button">
            Move Position
          </Button>
        </div>
      </div>
      {vm.showBanner && (
        <div className="bg-yellow-100 flex justify-center gap-2 px-4 py-3">
          <AvatarButtonGroup
            avatars={avatarGroupProps(vm.users, {
              href: true,
              stateTooltip: true,
            })}
            className="pr-1"
            size="xs"
            limit={4}
          />
          <p className="mb-0 text-sm text-yellow-800">
            {pluralize('people', vm.users.length, true)} will be moved to the
            team and track you choose.
          </p>
        </div>
      )}
    </GlobalModal.Root>
  )
})
