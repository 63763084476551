import { ArrowsOut, LinkSimple } from '@phosphor-icons/react'
import {
  ConfirmationDialog,
  OverflowMenu,
  Tag,
  Tooltip,
  useModalContext,
} from 'src/design-system'
import * as MegaModal from '../../mega-modal'
import { observer } from 'mobx-react-lite'
import cn from 'classnames'
import React from 'react'
import { Discipline } from 'store/modules/disciplines'
import { errorToast } from '../../../utils/error-toast'
import { MOVE_POSITION_DIALOG_ID } from 'components/move-position-dialog/utils'
import { MovePositionDialogProps } from 'components/move-position-dialog'
import { NavVm } from './nav-vm'
import { Position } from 'store/modules/positions'
import { PreviousNextPositionButtons } from './previous-next-position-buttons'
import { store } from 'store/index'
import { successToast } from '../../../utils/success-toast'
import { trackEvent } from '../../../services/event-tracker'

export type PositionModalNavProps = {
  discipline: Discipline
  editable?: boolean
  position: Position
  onChangePosition?: (positionId: string, disciplineId: string) => void
  showCloseButton?: boolean
}

export const Nav = observer((props: PositionModalNavProps) => {
  const { discipline, editable, position, onChangePosition, showCloseButton } =
    props

  const { closeModal, openModal } = useModalContext()

  const vm = React.useMemo(() => {
    return new NavVm(position, discipline)
  }, [position, discipline])

  const onClick = (action: string) => {
    trackEvent('$track_position_modal_cta_clicked', { action })
  }

  const onConfirm = React.useCallback(async () => {
    const result = await position.destroy()
    if (result.success) {
      successToast('Position deleted')
      closeModal()
      vm.dispatchEvents()
    } else {
      errorToast()
    }
  }, [closeModal, position, vm])

  const onDuplicate = async () => {
    const result = await store.positions.create({ origin: position.id })
    if (result.success) {
      successToast('Position duplicated')
      closeModal()
      vm.dispatchEvents()

      if (vm.team && !vm.onPositionsPage) {
        window.location.href = vm.team.baseUrl
      }
    } else {
      errorToast()
    }
  }

  const onMove = () => {
    openModal<MovePositionDialogProps>(MOVE_POSITION_DIALOG_ID, { position })
  }

  const positionPath = position.url(discipline.id)

  return (
    <div className="flex flex-row justify-between items-center w-full">
      <div className="flex flex-row items-center gap-2">
        {discipline && (
          <PreviousNextPositionButtons
            onChangePosition={onChangePosition}
            vm={vm}
          />
        )}
        <div className="flex flex-row">
          {(position.team || discipline) && (
            <MegaModal.Breadcrumb breadcrumbs={vm.breadcrumbs} />
          )}
        </div>
        {position.hiringLink && (
          <Tag className="bg-yellow-100 text-yellow-800">Hiring</Tag>
        )}
      </div>
      <div className="flex flex-row gap-4 items-center">
        {vm.canCopyLink && (
          <Tooltip content="Copy link">
            <button
              onClick={() => vm.copyLink()}
              className="text-gray-300 hover:text-theme-40 leading-none"
            >
              <LinkSimple weight="bold" className="w-4 h-4" />
            </button>
          </Tooltip>
        )}
        {showCloseButton && (
          <Tooltip content="Open as Page">
            <a
              href={positionPath}
              className="text-gray-300 hover:text-theme-40 leading-none"
            >
              <ArrowsOut weight="bold" className="w-4 h-4" />
            </a>
          </Tooltip>
        )}
        <MegaModal.RightPaneButton />
        {position.team && (
          <>
            <ConfirmationDialog.Root
              body="This will permanently remove this position from Progression."
              onConfirm={onConfirm}
              title={`Are you sure you want to delete this position?`}
            >
              <OverflowMenu.Root contentClassName="min-w-[160px]" right>
                {vm.canCopyLink && (
                  <OverflowMenu.Button onClick={() => vm.copyLink()}>
                    Copy Link
                  </OverflowMenu.Button>
                )}
                <OverflowMenu.SubMenu>
                  <OverflowMenu.SubMenuTrigger>
                    Download
                  </OverflowMenu.SubMenuTrigger>
                  <OverflowMenu.Content subMenu>
                    <OverflowMenu.Link
                      className="block p-2 text-gray-900 w-full"
                      onClick={() => onClick('download-csv')}
                      href={`${positionPath}.csv`}
                      isExternal
                    >
                      CSV
                    </OverflowMenu.Link>
                    <OverflowMenu.Link
                      className="block p-2 text-gray-900 w-full"
                      onClick={() => onClick('download-pdf')}
                      href={`${positionPath}.pdf`}
                      isExternal
                    >
                      PDF
                    </OverflowMenu.Link>
                  </OverflowMenu.Content>
                </OverflowMenu.SubMenu>
                {editable && (
                  <>
                    <OverflowMenu.Button
                      onClick={() => {
                        onClick('duplicate')
                        onDuplicate()
                      }}
                    >
                      Duplicate
                    </OverflowMenu.Button>
                    <OverflowMenu.Button
                      onClick={() => {
                        onClick('move')
                        onMove()
                      }}
                    >
                      Move To<span aria-hidden>..</span>
                    </OverflowMenu.Button>
                    <ConfirmationDialog.Trigger asChild>
                      <OverflowMenu.Button
                        onClick={() => onClick('delete')}
                        variant="destructive"
                      >
                        Delete
                      </OverflowMenu.Button>
                    </ConfirmationDialog.Trigger>
                  </>
                )}
              </OverflowMenu.Root>
            </ConfirmationDialog.Root>
          </>
        )}
        {showCloseButton && <MegaModal.CloseButton />}
      </div>
    </div>
  )
})
