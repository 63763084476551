import { Link } from 'src/design-system'
import * as React from 'react'
import { Input } from 'components/atoms/input'
import { urlWithoutProtocol } from 'app/packs/src/utils/url-helpers'
import * as MegaModal from '../../mega-modal'

type HiringLinkInfoLineProps = {
  editable?: boolean
  hiringLink: string | null
  invalid?: boolean
  onBlur: () => void
  onChange: (value: string | null) => void
  value?: string | null
}

export const HiringLinkInfoLine: React.VFC<HiringLinkInfoLineProps> = (
  props
) => {
  const { editable, hiringLink, invalid, onBlur, onChange, value } = props

  if (!editable && !hiringLink) return null

  return (
    <MegaModal.InfoLine
      label="Hiring link"
      value={
        editable ? (
          <EditableValue
            invalid={invalid}
            onBlur={onBlur}
            onChange={onChange}
            value={value}
          />
        ) : (
          <Value hiringLink={hiringLink} />
        )
      }
    />
  )
}

const EditableValue: React.VFC<{
  invalid?: boolean
  onBlur: () => void
  onChange: (value: string | null) => void
  value?: string | null
}> = (props) => {
  const { invalid, onBlur, onChange, value } = props

  return (
    <Input
      className="-ml-4"
      invalid={invalid}
      naked
      nonFocusedPlaceholder={<p className="mb-0 text-theme-40">Add link</p>}
      onBlur={onBlur}
      onChange={onChange}
      placeholder="https://"
      type="text"
      value={value}
    />
  )
}

const Value: React.VFC<{ hiringLink: string | null }> = (props) => {
  const { hiringLink } = props

  if (!hiringLink) return null

  return (
    <Link
      className="p-0 truncate"
      href={hiringLink}
      underline={false}
      data-turbo-frame="content"
      data-turbo-action="advance"
    >
      {urlWithoutProtocol(hiringLink)}
    </Link>
  )
}
