import { ModifiablePositionAttributes, Position } from 'store/modules/positions'
import { SelectOption } from '../atoms/select/select.types'
import { sortAlphaNumberValues } from '../../utils/sort-helpers'
import { store } from 'store/index'

export class MovePositionDialogVm {
  constructor(private position: Position, private teamId: string | null) {}

  get showBanner() {
    return this.users.length > 0
  }

  get teamOptions(): SelectOption[] {
    return store.teams.editableForCurrentUser
      .sort((a, b) => sortAlphaNumberValues(a.name, b.name))
      .map((team) => {
        return {
          label: team.name,
          value: team.id,
        }
      })
  }

  get trackOptions() {
    if (!this.teamId) return []

    const team = store.teams.byId(this.teamId)
    if (!team || !team.framework) return []

    return team.framework.disciplines.reduce<SelectOption[]>(
      (options, discipline) => {
        if (!this.position.hasDiscipline(discipline)) {
          options.push({
            label: discipline.name,
            value: discipline.id,
          })
        }

        return options
      },
      []
    )
  }

  get users() {
    return this.position.users
  }

  newErrors(formState: Partial<ModifiablePositionAttributes>) {
    const newErrors = []

    if (!this.teamId) newErrors.push('team')

    const disciplines = formState['disciplines']
    if (!disciplines || disciplines.length === 0) newErrors.push('disciplines')

    return newErrors
  }

  onSuccess(previousTeamId?: string) {
    document.dispatchEvent(new Event('frameworkpage:updated'))
    document.dispatchEvent(new Event('hydrate'))

    if (previousTeamId && previousTeamId !== this.teamId) {
      store.teams.fetchOne(previousTeamId)
    }
  }

  async updatePosition(formState: Partial<ModifiablePositionAttributes>) {
    return await store.positions.update(this.position.id, formState, {
      include: [
        'framework',
        'framework.disciplines',
        'framework.frameworks_skills',
        'framework.frameworks_skills.category',
        'framework.positions',
        'team',
      ],
    })
  }
}
