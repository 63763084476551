import { z } from 'zod'
import * as currencyCodes from '@dinero.js/currencies'
import { Currency } from 'app/packs/src/models/currency'
import { Position } from 'store/modules/positions'
import { store } from 'store/index'
import { RequestResult } from 'app/packs/src/api/types'
import { SalaryFormRowState } from './salary-row-vm'

export type SalaryFormState = SalaryFormRowState[]
export class SalaryDialogVm {
  constructor(private position: Position) {}

  static emptySalary = {
    currency: 'USD',
  }

  static defaultState(position: Position): SalaryFormState {
    return position.salaries?.length
      ? position.salaries.map((salary) => ({
          id: salary.id,
          currency: salary.currency,
          fromDollars: salary.from.dollars,
          toDollars: salary.to.dollars,
          location: salary.location,
        }))
      : [this.emptySalary]
  }

  async onSubmit(salaryFormState: SalaryFormState) {
    await Promise.all(
      this.position.salaries.map(async (salary) => {
        if (!salaryFormState.find((s) => s.id === salary.id)) {
          return store.salaries.destroy(salary.id)
        }
      })
    )

    const formState = this.schema.parse(salaryFormState)

    const result = await Promise.all(
      formState.map(async (salary) => {
        let res: RequestResult

        if (salary.id) {
          res = await store.salaries.update(salary.id, salary)
        } else {
          res = await store.salaries.create({
            ...salary,
            position: this.position.id,
          })
        }

        return res.success ? null : res.errors[0]?.detail
      })
    )

    const errors = result.filter((r) => !!r)

    if (errors.length > 0) {
      return { success: false, errors }
    } else {
      return { success: true }
    }
  }

  private get schema() {
    return z.array(
      z
        .object({
          currency: z.custom<keyof typeof currencyCodes>((value) => {
            return typeof value === 'string' && value in currencyCodes
          }),
          fromDollars: z.number().nullish().default(null),
          toDollars: z.number().nullish().default(null),
          id: z.string().optional(),
          location: z.string().optional().nullable(),
        })
        .transform((data) => ({
          ...data,
          fromCents: Currency.centsFromDollars(data.currency, data.fromDollars),
          toCents: Currency.centsFromDollars(data.currency, data.toDollars),
        }))
    )
  }
}
