import { observer } from 'mobx-react-lite'
import * as React from 'react'
import cn from 'classnames'
import { AssignedToInfoLine } from './assigned-to-info-line'
import { HiringLinkInfoLine } from './hiring-link-info-line'
import { InfoPaneVm } from './info-pane-vm'
import { LabelInfoLine } from './label-info-line'
import { ModifiablePositionAttributes, Position } from 'store/modules/positions'
import { SalaryInfoLine } from './salary-info-line'
import { SeniorityLevelInfoLine } from './seniority-level-info-line'
import { SkillsRadarChart } from 'components/skills-radar-chart'
import { store } from 'store/index'
import { TabbedInfoSection } from 'components/tabbed-info-section'
import { Button } from 'src/design-system'
import { ArrowRight } from '@phosphor-icons/react'

type InfoPaneProps = {
  editable?: boolean
  errors: string[]
  formState: Partial<ModifiablePositionAttributes>
  onBlur: (
    field: keyof ModifiablePositionAttributes,
    resetOnFailure?: boolean
  ) => void
  onChange: <Field extends keyof ModifiablePositionAttributes>(
    field: Field,
    value: ModifiablePositionAttributes[Field] | null
  ) => void
  position: Position
}

export const InfoPane = observer((props: InfoPaneProps) => {
  const { editable, errors, formState, onBlur, onChange, position } = props

  const vm = React.useMemo(() => {
    return new InfoPaneVm(position)
  }, [position])

  const onClick = () => {
    window.location.href = `/compare?pos_2=${position.slug}&source=info-pane`
  }

  const isTemplateTeam = position.team?.template

  const showSalaries =
    store.currentUser?.isAdmin || position.team.org.showSalaries

  const allowMultipleSalaries = store.featureGates.canAccess(
    'multiple_salary_bands'
  )

  return (
    <div className="flex flex-col gap-8">
      <div className="flex items-center gap-6">
        <SkillsRadarChart
          hideLevels
          onClick={onClick}
          series={vm.series}
          size={120}
        />
        <Button
          className="flex-grow-0 flex-shrink"
          variant="outline"
          colourVariant="theme"
          as="a"
          data-turbo-action="advance"
          href={`/compare?pos_1=${position.slug}&source=info-pane`}
        >
          Compare <ArrowRight />
        </Button>
      </div>
      <div className={cn('flex flex-col', editable ? 'gap-1' : 'gap-6')}>
        <LabelInfoLine
          editable={editable}
          invalid={errors.includes('customLabel')}
          labels={vm.labels}
          onBlur={() => onBlur('customLabel')}
          onChange={(value) => onChange('customLabel', value)}
          value={formState.customLabel ?? null}
        />
        <SeniorityLevelInfoLine
          editable={editable}
          invalid={errors.includes('seniorityLevel')}
          onBlur={() => onBlur('seniorityLevel', true)}
          onChange={(value) => onChange('seniorityLevel', value)}
          seniorityLevel={position.seniorityLevel}
          value={formState.seniorityLevel}
        />
        {showSalaries && !isTemplateTeam && (
          <SalaryInfoLine
            allowMultipleSalaries={allowMultipleSalaries}
            editable={editable}
            position={position}
          />
        )}
        {!isTemplateTeam && (
          <>
            <AssignedToInfoLine editable={editable} position={position} />
            <HiringLinkInfoLine
              editable={editable}
              hiringLink={position.hiringLink}
              invalid={errors.includes('hiringLink')}
              onBlur={() => onBlur('hiringLink')}
              onChange={(value: string | null) => onChange('hiringLink', value)}
              value={formState.hiringLink}
            />
          </>
        )}
      </div>
      {editable && <TabbedInfoSection resource={position} />}
    </div>
  )
})
