import { Banner, Button, Modal } from 'src/design-system'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import { Position } from 'store/modules/positions'
import { SalaryDialogVm, SalaryFormState } from './salary-dialog-vm'
import { successToast } from 'app/packs/src/utils/success-toast'
import { SalaryRow } from './salary-row'
import { SalaryFormRowState } from './salary-row-vm'
import { modifyAtIndex, removeIndex } from 'app/packs/src/utils/array-helpers'

export type SalaryDialogProps = {
  children: React.ReactElement
  position: Position
  defaultOpen?: boolean
  allowMultipleSalaries?: boolean
}

export const SalaryDialog = observer<SalaryDialogProps>((props) => {
  const { allowMultipleSalaries, children, position, defaultOpen } = props

  const [error, setError] = React.useState('')

  const [open, setOpen] = React.useState(defaultOpen)

  const [salaryFormState, setSalaryFormState] = React.useState<SalaryFormState>(
    () => SalaryDialogVm.defaultState(position)
  )
  const vm = React.useMemo(() => {
    return new SalaryDialogVm(position)
  }, [position])

  const onSubmit = async () => {
    const result = await vm.onSubmit(salaryFormState)
    if (result.success) {
      successToast('Position updated')
      setError('')
      setSalaryFormState(SalaryDialogVm.defaultState(position))
      setOpen(false)
    } else if (result.errors) {
      setError(result.errors[0])
    }
  }

  const onAddSalaryClick = React.useCallback(() => {
    setSalaryFormState((salaryFormState) => [
      ...salaryFormState,
      SalaryDialogVm.emptySalary,
    ])
  }, [])

  const clearSalary = (index: number) => {
    setError('')
    setSalaryFormState((salaryFormState) => removeIndex(salaryFormState, index))
  }

  const onNewSalaryChange = <Field extends keyof SalaryFormRowState>(
    field: Field,
    index: number,
    value: SalaryFormRowState[Field] | null
  ) => {
    setError('')
    setSalaryFormState((salaryFormState) =>
      modifyAtIndex(salaryFormState, index, {
        ...salaryFormState[index],
        [field]: value,
      })
    )
  }

  const salariesToShow = allowMultipleSalaries
    ? salaryFormState
    : salaryFormState.slice(0, 1)

  if (!position || !vm) return null

  return (
    <Modal.Root
      className="w-[720px]"
      data-testid="salary-dialog"
      onOpenChange={setOpen}
      open={open}
      title={position.name}
      trigger={children}
    >
      <div className="flex flex-col w-full justify-between p-8 border-t-0 border-r-0 border-l-0 border-b border-gray-200 border-solid">
        <div className="flex flex-col gap-2 mb-5">
          <div className="font-bold text-lg">
            {allowMultipleSalaries ? 'Edit Salaries' : 'Edit Salary'}
          </div>
          <div className="text-gray-600">
            For a fixed salary leave the “To” field empty
          </div>
        </div>
        <table className="table-header-group">
          <thead>
            {salariesToShow.length > 0 && (
              <tr className="text-sm font-bold">
                <th className="pb-2">Currency</th>
                <th className="pb-2">From</th>
                <th className="pb-2">To</th>
                <th className="pb-2">Location</th>
              </tr>
            )}
          </thead>
          <tbody>
            {salariesToShow.map((salary, index) => (
              <SalaryRow
                key={`${salariesToShow.length}-${index}`}
                salary={salary}
                vm={vm}
                onChange={(field, value) =>
                  onNewSalaryChange(field, index, value)
                }
                onDelete={() => clearSalary(index)}
                showDelete={
                  salariesToShow.length > 1 || position.salaries?.length > 0
                }
              />
            ))}
          </tbody>
        </table>
        {error && (
          <Banner variant="danger" className="rounded mb-3">
            {error}
          </Banner>
        )}
        {allowMultipleSalaries && (
          <Button
            variant="outline"
            colourVariant="paper"
            type="button"
            onClick={onAddSalaryClick}
            className="border-0 bg-transparent mb-7 -ml-2"
          >
            <span className="text-theme-40">Add another salary</span>
          </Button>
        )}
        <div className="flex flex-row justify-end gap-2">
          <Button
            onClick={() => {
              setError('')
              setOpen(false)
              setSalaryFormState(SalaryDialogVm.defaultState(position))
            }}
            type="button"
            variant="outline"
          >
            Cancel
          </Button>
          <Button onClick={onSubmit} type="button">
            Save
          </Button>
        </div>
      </div>
    </Modal.Root>
  )
})
