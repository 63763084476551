import { Position } from 'store/modules/positions'
import { store } from 'store/index'

export class InfoPaneVm {
  constructor(private position: Position) {}

  get disciplinesForTeam() {
    if (!this.position.team?.framework) return []

    return this.position.team.framework.disciplines
  }

  get labels() {
    if (!this.position.team?.showPositionLabels) return []
    if (this.position.customLabel) return [this.position.customLabel]

    return this.position.disciplines.map((discipline) =>
      this.position.positionCode(discipline.initials)
    )
  }

  get series() {
    return {
      position: {
        className: 'text-theme-40',
        colour: 'currentColor',
        skills: this.skills,
      },
    }
  }

  private get skills() {
    return store.positionSkills
      .forPosition(this.position.id)
      .map((positionSkill) => {
        return {
          id: positionSkill.skill.id,
          category: positionSkill.categoryName || 'Uncategorised',
          level: positionSkill.level,
          name: positionSkill.skill.name,
        }
      })
  }
}
