import { Link } from 'src/design-system'
import { observer } from 'mobx-react-lite'
import React from 'react'
import { Discipline } from 'store/modules/disciplines'
import { InfoPane } from './info-pane'
import { LoadingContent } from 'components/loading-content'
import { ModifiablePositionAttributes } from 'store/modules/positions'
import { MovePositionDialog } from 'components/move-position-dialog'
import { Nav } from './nav'
import { PositionModalHeader } from './header'
import { RequiredSkillsSection } from './required-skills-section'
import { successToast } from '../../utils/success-toast'
import { trackEvent } from '../../services/event-tracker'
import { useStore } from 'store/context'
import * as MegaModal from '../mega-modal'

export type PositionModalContentProps = {
  disciplineId?: string
  positionId: string
  onChangePosition?: (positionId: string, disciplineId: string) => void
  showCloseButton?: boolean
  source?: string
}

export const PositionModalContent = observer<PositionModalContentProps>(
  ({ disciplineId, positionId, onChangePosition, showCloseButton, source }) => {
    const {
      changes,
      comments,
      currentUser,
      disciplines,
      positions,
      positionSkills,
    } = useStore()

    const [errors, setErrors] = React.useState<string[]>([])

    const [formState, setFormState] = React.useState<
      Partial<ModifiablePositionAttributes>
    >({})

    const [loading, setLoading] = React.useState(true)

    const position = positions.byId(positionId, { useDefaultFilter: false })

    const discipline =
      disciplines.byId(disciplineId) ?? (position?.disciplines[0] as Discipline)

    React.useEffect(() => {
      if (!positionId) return

      const fetchData = async () => {
        const positionFetch = positions.fetchOne(positionId, {
          include: [
            'framework',
            'framework.disciplines',
            'framework.frameworks_skills.category',
            'framework.positions',
            'requirements',
            'requirements.skill_level',
            'requirements.skill_level.outcomes',
            'salaries',
          ],
        })

        const positionSkillsFetch = positionSkills.fetchForPosition(positionId)

        await Promise.all([positionFetch, positionSkillsFetch])

        setLoading(false)
      }

      fetchData()

      comments.fetchAll({
        filter: { commentable_id: positionId, commentable_type: 'Position' },
      })
    }, [comments, positionId, positions, positionSkills])

    React.useEffect(() => {
      if (!position) return

      setFormState({
        customLabel: position.customLabel,
        description: position.description,
        disciplines: position.disciplineIds,
        hiringLink: position.hiringLink,
        name: position.name,
        seniorityLevel: position.seniorityLevel,
      })

      trackEvent('$track_position_viewed', {
        format: 'modal',
        positionId: position.id,
        positionName: position.name,
        source,
      })
    }, [position, source])

    const editable =
      currentUser &&
      position?.team &&
      ((position.team.org && currentUser.isAdminOfOrg(position.team.org.id)) ||
        position.team.hasEditor(currentUser.id))

    const clearFieldErrors = (field: string) => {
      setErrors(errors.filter((error) => error !== field))
    }

    const onBlur = async (
      field: keyof typeof formState,
      resetOnFailure?: boolean
    ) => {
      if (!position) return
      if (formState[field] === position[field]) return

      const result = await positions.update(position.id, formState)
      if (result.success) {
        clearFieldErrors(field)
        successToast('Position updated')
        document.dispatchEvent(new Event('hydrate'))
        changes.fetchForResource(positionId, 'Position', 1)
      } else {
        if (resetOnFailure) {
          clearFieldErrors(field)
          setField(field, position[field])
        } else {
          if (!errors.includes(field)) setErrors([...errors, field])
        }
      }
    }

    const setField = <Field extends keyof ModifiablePositionAttributes>(
      field: Field,
      value: ModifiablePositionAttributes[Field] | null
    ) => {
      setFormState((formState) => ({ ...formState, [field]: value }))
    }

    return (
      <LoadingContent className="pt-10" loading={loading}>
        {position ? (
          <>
            <MegaModal.Nav>
              <Nav
                discipline={discipline}
                editable={editable}
                position={position}
                onChangePosition={onChangePosition}
                showCloseButton={showCloseButton}
              />
            </MegaModal.Nav>
            <MegaModal.Body>
              <MegaModal.Header>
                <PositionModalHeader
                  editable={editable}
                  errors={errors}
                  formState={formState}
                  onBlur={onBlur}
                  onChange={setField}
                  position={position}
                />
              </MegaModal.Header>
              <MegaModal.Content>
                <RequiredSkillsSection
                  editable={editable}
                  position={position}
                />
              </MegaModal.Content>
            </MegaModal.Body>
            <MegaModal.RightPane>
              <InfoPane
                editable={editable}
                errors={errors}
                formState={formState}
                onBlur={onBlur}
                onChange={setField}
                position={position}
              />
            </MegaModal.RightPane>
            <MovePositionDialog />
          </>
        ) : (
          <NotFound />
        )}
      </LoadingContent>
    )
  }
)

const NotFound: React.VFC = () => {
  return (
    <div className="min-h-screen w-full h-full flex items-center justify-center">
      <div className="text-center">
        <h1 className="text-3xl font-bold mb-4">Position not found</h1>
        <p className="text-gray-600">We could not find this Position.</p>
        <Link
          href="/"
          variant="brand"
          data-turbo-frame="content"
          data-turbo-action="advance"
        >
          Back Home
        </Link>
      </div>
    </div>
  )
}
