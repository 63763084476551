import {
  canCopyToClipboard,
  copyToClipboard,
} from 'app/packs/src/utils/clipboard'
import { successToast } from 'app/packs/src/utils/success-toast'
import { Breadcrumb } from 'components/mega-modal/breadcrumb'
import { Discipline } from 'store/modules/disciplines'
import { Framework } from 'store/modules/frameworks'
import { Position } from 'store/modules/positions'
import { Team } from 'store/modules/teams'

export class NavVm {
  constructor(private position: Position, public discipline: Discipline) {}

  dispatchEvents() {
    document.dispatchEvent(new Event('frameworkpage:updated'))
    document.dispatchEvent(new Event('hydrate'))
  }

  positionAndDisciplineFor(nextOrPrevious: 'Next' | 'Previous') {
    let position: Position | undefined
    let discipline = this.discipline

    if (nextOrPrevious === 'Next') {
      position = this.nextPosition
      if (!position && this.nextDiscipline) {
        discipline = this.nextDiscipline
        position = discipline?.positions[0]
      }
    } else {
      position = this.previousPosition
      if (!position && this.previousDiscipline) {
        discipline = this.previousDiscipline
        position = discipline?.positions[discipline?.positions.length - 1]
      }
    }

    return { position, discipline }
  }

  positionCode(position: Position, discipline = this.discipline) {
    return position.positionCode(discipline.initials)
  }

  get onPositionsPage() {
    return this.team ? window.location.pathname === this.team.baseUrl : false
  }

  get previousPosition(): Position | undefined {
    const positions = this.positions
    const index = positions.findIndex(
      (position) => position.id === this.position.id
    )
    return positions[index - 1]
  }

  get nextPosition(): Position | undefined {
    const positions = this.positions
    const index = positions.findIndex(
      (position) => position.id === this.position.id
    )
    return positions[index + 1]
  }

  get previousDiscipline(): Discipline | undefined {
    const framework = this.position.framework as Framework
    const currentIndex = framework.disciplines.findIndex(
      (d) => d.id === this.discipline.id
    )
    return framework.disciplines[currentIndex - 1]
  }

  get nextDiscipline(): Discipline | undefined {
    const framework = this.position.framework as Framework
    const currentIndex = framework.disciplines.findIndex(
      (d) => d.id === this.discipline.id
    )
    return framework.disciplines[currentIndex + 1]
  }

  get breadcrumbs(): Breadcrumb[] {
    let values: Breadcrumb[] = []
    if (this.team) {
      values = [
        { label: 'Teams', url: this.team.org.basePath },
        { label: this.team.name, url: this.team.baseUrl },
      ]
    } else {
      values = [{ label: 'Frameworks', url: '/frameworks' }]
    }

    return [...values, this.position.name]
  }

  get positions() {
    return this.discipline.positions
  }

  get team(): Team | undefined {
    return this.position.team
  }

  get canCopyLink() {
    return canCopyToClipboard
  }

  copyLink() {
    if (copyToClipboard(this.position.url(this.discipline.id))) {
      successToast('Link copied to clipboard')
    }
  }
}
