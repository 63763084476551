import * as React from 'react'
import { Input } from 'components/atoms/input'
import * as MegaModal from '../../mega-modal'

type SeniorityLevelInfoLineProps = {
  editable?: boolean
  invalid?: boolean
  onBlur: () => void
  onChange: (value: number | null) => void
  seniorityLevel: number
  value?: number | null
}

export const SeniorityLevelInfoLine: React.VFC<SeniorityLevelInfoLineProps> = (
  props
) => {
  const { editable, invalid, onBlur, onChange, seniorityLevel, value } = props

  return (
    <MegaModal.InfoLine
      label="Seniority level"
      value={
        editable ? (
          <EditableValue
            invalid={invalid}
            onBlur={onBlur}
            onChange={(value) => onChange(value ? parseInt(value) : null)}
            value={value ? value.toString() : null}
          />
        ) : (
          seniorityLevel
        )
      }
    />
  )
}

const EditableValue = (props: {
  invalid?: boolean
  onBlur: () => void
  onChange: (value: string | null) => void
  value?: string | null
}) => {
  const { invalid, onBlur, onChange, value } = props

  return (
    <Input
      className="-ml-4"
      invalid={invalid}
      min={1}
      naked
      name="seniority_level"
      aria-label="Seniority level"
      onBlur={onBlur}
      onChange={onChange}
      required
      type="number"
      value={value}
    />
  )
}
