import { AvatarButtonGroup, Button } from 'src/design-system'
import { observer } from 'mobx-react-lite'
import { UserPlus } from '@phosphor-icons/react'
import * as React from 'react'
import { avatarGroupProps } from 'app/packs/src/utils/user-helpers'
import { InputWrapper } from 'components/atoms/input'
import { openModal as openRailsModal } from 'app/packs/src/utils/open-modal'
import { Position } from 'store/modules/positions'
import { User } from 'store/modules/users'
import { useStore } from 'store/context'
import * as MegaModal from '../../mega-modal'

type AssignedToInfoLineProps = {
  editable?: boolean
  position: Position
}

export const AssignedToInfoLine = observer((props: AssignedToInfoLineProps) => {
  const { editable, position } = props

  const { currentUser } = useStore()

  if (!currentUser) return null

  const users = position.users

  if (
    !editable &&
    (!position.team?.org?.membershipsVisible || users.length === 0)
  ) {
    return null
  }

  return (
    <MegaModal.InfoLine
      label="Assigned to"
      value={
        editable ? (
          <EditableValue
            currentUser={currentUser}
            positionId={position.id}
            users={users}
          />
        ) : (
          <Value users={users} />
        )
      }
    />
  )
})

const EditableValue: React.VFC<{
  currentUser: User
  positionId: string
  users: User[]
}> = (props) => {
  const { currentUser, positionId, users } = props

  const onClickAddUser = () => {
    if (!currentUser.org) return

    openRailsModal(
      `/assign_user_to_position_modal?limit=2&org_id=${currentUser.org.id}&position_id=${positionId}&origin=position_modal`
    )
  }

  return (
    <InputWrapper className="-ml-4">
      {users.length === 0 ? (
        <Button
          className="bg-transparent border-0 flex focus:shadow-none h-full items-center justify-start px-4 text-theme-40"
          fullWidth
          onClick={onClickAddUser}
        >
          <div className="border border-gray-200 h-5 rounded-full w-5">
            <UserPlus className="h-3 text-gray-600 w-3" />
          </div>
          Add teammate
        </Button>
      ) : (
        <AvatarButtonGroup
          avatars={avatarGroupProps(users, {
            href: true,
            stateTooltip: true,
          })}
          className="px-4 py-3"
          eventHandlers={{ onClickAddUser }}
          permissions={{ allowAddUser: true }}
          limit={3}
          size="xs"
        />
      )}
    </InputWrapper>
  )
}

const Value: React.VFC<{ users: User[] }> = (props) => {
  const { users } = props

  return (
    <AvatarButtonGroup
      avatars={avatarGroupProps(users, {
        href: true,
        stateTooltip: true,
      })}
      limit={3}
      size="xs"
    />
  )
}
