import { errorToast } from 'app/packs/src/utils/error-toast'
import { successToast } from 'app/packs/src/utils/success-toast'
import { EditorContext } from 'components/atoms/editor'
import { store } from 'store/index'
import { AsyncTaskOutput } from 'store/modules/async-tasks'
import { Position } from 'store/modules/positions'

export const onUseGeneratedPositionDescription = async (
  output: AsyncTaskOutput,
  position: Position,
  editor: React.RefObject<EditorContext>
) => {
  const content = output.positionDescription?.output.extracted || ''

  const updated = await store.positions.update(position.id, {
    description: content,
  })

  if (updated.success) {
    successToast('Applied AI position description ✨')
    const DOC = {
      type: 'doc',
      content: [
        {
          type: 'paragraph',
          content: [
            {
              type: 'text',
              text: content,
            },
          ],
        },
      ],
    }
    editor.current?.reset(DOC)
  } else {
    errorToast(
      'Sorry, there was a problem applying the AI description, please try again.'
    )
  }
}
