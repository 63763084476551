import { Button, Link, Tooltip } from 'src/design-system'
import { CaretDown, CaretUp } from '@phosphor-icons/react'
import * as React from 'react'
import { NavVm } from './nav-vm'

type PreviousNextPositionButtonsProps = {
  onChangePosition?: (positionId: string, disciplineId: string) => void
  vm: NavVm
}

export const PreviousNextPositionButtons: React.VFC<
  PreviousNextPositionButtonsProps
> = (props) => {
  const { onChangePosition, vm } = props

  return (
    <div className="flex gap-1 items-center">
      <PreviousNextPositionButton
        nextOrPrevious="Next"
        onClick={onChangePosition}
        vm={vm}
      />
      <PreviousNextPositionButton
        nextOrPrevious="Previous"
        onClick={onChangePosition}
        vm={vm}
      />
    </div>
  )
}

const PreviousNextPositionButton: React.VFC<{
  nextOrPrevious: 'Next' | 'Previous'
  onClick?: (positionId: string, disciplineId: string) => void
  vm: NavVm
}> = (props) => {
  const { nextOrPrevious, onClick, vm } = props

  const Icon = nextOrPrevious === 'Next' ? CaretUp : CaretDown
  const { discipline, position } = vm.positionAndDisciplineFor(nextOrPrevious)

  if (position) {
    const positionCode = vm.positionCode(position, discipline)
    const positionId = position.id
    const disciplineId = discipline.id

    return (
      <Link
        className="border-theme-40 border-px border-solid rounded p-2 leading-none hover:bg-gray-50"
        onClick={(e) => {
          if (!onClick) return
          e.preventDefault()
          onClick(positionId, disciplineId)
        }}
        href={position.url(disciplineId)}
        variant="brand"
        data-turbo-frame="content"
        data-turbo-action="advance"
      >
        <Tooltip content={positionCode}>
          <Icon className="h-4 text-theme-40 w-4" weight="bold" />
          <span className="sr-only">{positionCode}</span>
        </Tooltip>
      </Link>
    )
  }

  return (
    <Button
      as="button"
      className="border-gray-200 text-gray-200"
      disabled
      variant="outline"
    >
      <Icon className="h-4 text-theme-600 w-4" weight="bold" />
      <span className="sr-only">{nextOrPrevious} Position</span>
    </Button>
  )
}
