import * as currencyCodes from '@dinero.js/currencies'
import { SelectOption } from '../../atoms/select/select.types'

export type SalaryFormRowState = {
  id?: string
  currency: string
  fromDollars?: number | null
  toDollars?: number | null
  location?: string | null
}

export class SalaryRowVm {
  constructor(private salary: SalaryFormRowState) {}

  get currencyCodeOptions(): SelectOption[] {
    return Object.keys(currencyCodes).map((code, _i) => {
      return {
        label: code,
        value: code,
      }
    })
  }

  get defaultCurrency(): keyof typeof currencyCodes {
    return (this.salary?.currency as keyof typeof currencyCodes) || 'USD'
  }

  get defaultSalaryFrom(): number | undefined {
    return this.salary?.fromDollars || undefined
  }

  get defaultSalaryTo(): number | undefined {
    return this.salary?.toDollars || undefined
  }

  get defaultLocation(): string {
    return this.salary?.location || ''
  }
}
