import { Input } from 'src/design-system'
import cn from 'classnames'
import React from 'react'
import { AiGenerateButton } from 'components/ai/ai-generate-button'
import { Editor, EditorContext } from 'components/atoms/editor'
import { HtmlContent } from 'components/atoms/editor/html-content'
import { ModifiablePositionAttributes, Position } from 'store/modules/positions'
import { onUseGeneratedPositionDescription } from './on-position-description-use'
import styles from './styles.module.scss'

type PositionModalHeaderProps = {
  editable?: boolean
  errors: string[]
  formState: Partial<ModifiablePositionAttributes>
  onBlur: (
    field: keyof ModifiablePositionAttributes,
    resetOnFailure?: boolean
  ) => void
  onChange: (
    field: keyof ModifiablePositionAttributes,
    value: string | null
  ) => void
  position: Position
}

export const PositionModalHeader = (props: PositionModalHeaderProps) => {
  const { editable, errors, formState, onBlur, onChange, position } = props

  const editor = React.useRef<EditorContext>(null)

  return (
    <>
      {editable && (
        <div className="-mx-2">
          <Input
            className="text-3xl font-bold py-2 pl-2 hover:border-gray-200 focus-within:!border-theme-60 focus-within:!shadow-none"
            isInvalid={errors.includes('name')}
            key={`input-${position.id}`}
            naked
            name="name"
            onBlur={() => onBlur('name', true)}
            onChange={(e) => onChange('name', e.target.value)}
            value={formState.name}
          />
          <div className="relative group">
            <Editor
              className="border-transparent hover:border-gray-200 focus-within:!border-theme-60 focus-within:!shadow-none pr-12 text-gray-600"
              initialContent={position.description || ''}
              invalid={errors.includes('description')}
              key={`editor-${position.id}`}
              name="description"
              onBlur={() => onBlur('description')}
              onChange={(value) => onChange('description', value)}
              placeholder="Add description"
              ref={editor}
              textSize="base"
            />
            {editable && (
              <AiGenerateButton
                className="absolute right-1 bottom-1 opacity-0 group-hover:opacity-100 transition-all ease-in-out duration-200"
                source="inline-position-description"
                inputs={{ positionName: formState.name }}
                contentType="positionDescription"
                onUse={async (output) =>
                  onUseGeneratedPositionDescription(output, position, editor)
                }
                tooltipContent="Generate a description using our AI Assistant"
              />
            )}
          </div>
        </div>
      )}

      {!editable && (
        <>
          <div className="font-bold text-3xl">{position.name}</div>
          {position.description && (
            <HtmlContent className={cn('mt-4', styles.description)}>
              {position.description}
            </HtmlContent>
          )}
        </>
      )}
    </>
  )
}
