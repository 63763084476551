import { Button, Tooltip } from 'src/design-system'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import cn from 'classnames'
import { InputWrapper } from 'components/atoms/input'
import { Position } from 'store/modules/positions'
import { SalaryDialog } from '../salary-dialog'
import * as MegaModal from '../../mega-modal'
import styles from './styles.module.scss'
import { Salary } from 'store/modules/salaries'

type SalaryInfoLineProps = {
  allowMultipleSalaries?: boolean
  editable?: boolean
  position: Position
}

export const SalaryInfoLine = observer((props: SalaryInfoLineProps) => {
  const { allowMultipleSalaries, editable, position } = props

  const [showAll, setShowAll] = React.useState(false)

  const index = allowMultipleSalaries && showAll ? undefined : 3
  const salariesToShow = [...position.salaries.slice(0, index)]

  if (!editable && !position.salaries.length) return null

  const displayValue = () => {
    if (editable) {
      return (
        <EditableValue
          position={position}
          showAll={showAll}
          setShowAll={setShowAll}
          salariesToShow={salariesToShow}
          allowMultipleSalaries={allowMultipleSalaries}
        />
      )
    } else if (salariesToShow?.length) {
      return (
        <div className="flex flex-col items-start">
          <FormattedSalaries salaries={salariesToShow} />
          {position.salaries.length > 3 && allowMultipleSalaries && (
            <ShowHideButton showAll={showAll} setShowAll={setShowAll} />
          )}
        </div>
      )
    }
  }

  const label = allowMultipleSalaries ? 'Salaries' : 'Salary'

  if (!editable && !position.salaries.length) return null

  return (
    <MegaModal.InfoLine
      label={label}
      className="!items-start"
      labelProps={{
        className: editable ? 'mt-3' : 'mt-1',
      }}
      value={displayValue()}
    />
  )
})

const EditableValue: React.VFC<{
  position: Position
  allowMultipleSalaries?: boolean
  showAll: boolean
  setShowAll: (value: boolean) => void
  salariesToShow?: Salary[]
}> = (props) => {
  const {
    allowMultipleSalaries,
    position,
    showAll,
    setShowAll,
    salariesToShow,
  } = props

  return (
    <InputWrapper className="-ml-4 truncate h-full">
      <div className="flex flex-col items-start">
        <SalaryDialog
          position={position}
          allowMultipleSalaries={allowMultipleSalaries}
        >
          <Button
            className={cn(
              'px-4 py-3 focus:shadow-none bg-transparent',
              position.salaries.length ? 'text-gray-900' : 'text-theme-40',
              styles.button
            )}
          >
            {salariesToShow?.length ? (
              <FormattedSalaries salaries={salariesToShow} />
            ) : (
              <div className="truncate">Add salary range</div>
            )}
          </Button>
        </SalaryDialog>
        {position.salaries.length > 3 && allowMultipleSalaries && (
          <ShowHideButton
            showAll={showAll}
            setShowAll={setShowAll}
            className="px-4"
          />
        )}
      </div>
    </InputWrapper>
  )
}

const ShowHideButton: React.VFC<{
  showAll: boolean
  setShowAll: (value: boolean) => void
  className?: string
}> = (props) => {
  const { showAll, setShowAll, className } = props

  return (
    <button
      className={cn(
        'text-sm text-theme-40 py-3 focus-visible:outline-none',
        className
      )}
      onClick={() => setShowAll(!showAll)}
    >
      {showAll ? 'Hide all' : 'Show all'}
    </button>
  )
}

const FormattedSalaries: React.VFC<{
  salaries: Salary[]
}> = observer((props) => {
  const { salaries } = props

  if (!salaries.length) return null

  return (
    <div className="flex flex-col truncate">
      <div className="flex flex-col gap-3">
        {salaries.map((salary) => {
          if (!salary.formatted) return null
          return (
            <Tooltip content={salary.currency} side="bottom" key={salary.id}>
              <div className="flex flex-col items-start">
                {salary.location && (
                  <div className="text-xs text-gray-600">{salary.location}</div>
                )}
                <div className="flex flex-row items-center gap-1.5 text-sm">
                  {salary.formatted}
                </div>
              </div>
            </Tooltip>
          )
        })}
      </div>
    </div>
  )
})
