import { observer } from 'mobx-react-lite'
import * as React from 'react'
import { Position } from 'store/modules/positions'
import { RequiredSkillCard } from './required-skill-card'
import { RequiredSkillsEmptyState } from './required-skills-empty-state'
import { RequiredSkillsSectionVm } from './required-skills-section-vm'
import { Plus } from '@phosphor-icons/react'
import { AddSkillsDropdown } from './add-skills-dropdown'

type RequiredSkillsSectionProps = {
  editable?: boolean
  position: Position
}

export const RequiredSkillsSection = observer(
  (props: RequiredSkillsSectionProps) => {
    const { editable, position } = props

    const [isViewing, setIsViewing] = React.useState(false)

    const [outcomeState, setOutcomeState] = React.useState<Record<
      string,
      boolean
    > | null>(null)

    const vm = React.useMemo(() => {
      return new RequiredSkillsSectionVm(outcomeState, position)
    }, [outcomeState, position])

    const onOpenChange = (open: boolean, requirementId: string) => {
      const newOutcomeState = vm.newOutcomeState(open, requirementId)
      const newIsViewing = vm.newIsViewing(
        isViewing,
        Object.values(newOutcomeState)
      )

      setIsViewing(newIsViewing)
      setOutcomeState(newOutcomeState)
    }

    const teamSlug = position.team?.slug
    const addSkillsPath = `/teams/${teamSlug}/framework?position_id=${position.id}`

    return (
      <>
        <div className="flex items-center gap-3 mb-6">
          <div className="flex gap-2 items-center">
            <h5 className="text-base font-normal text-gray-500">
              {position.requirements.length} required skills
            </h5>
          </div>
          {editable && (
            <div className="flex gap-2 items-center text-base text-theme-40">
              <AddSkillsDropdown
                position={position}
                addSkillsPath={addSkillsPath}
              >
                {' '}
                <Plus size={16} />
              </AddSkillsDropdown>
            </div>
          )}
        </div>
        {vm.hasRequirements ? (
          Object.entries(vm.sortedCategoriesWithRequirements).map(
            ([categoryName, requirements]) => (
              <div className="pb-12 flex flex-col gap-2" key={categoryName}>
                <h3 className="font-bold text-gray-900 text-2xl">
                  {categoryName}
                </h3>
                <div className="flex flex-col -mx-4">
                  {requirements.map((requirement) => (
                    <RequiredSkillCard
                      key={requirement.id}
                      onOpenChange={(open) =>
                        onOpenChange(open, requirement.id)
                      }
                      requirement={requirement}
                      vm={vm}
                    />
                  ))}
                </div>
              </div>
            )
          )
        ) : (
          <RequiredSkillsEmptyState
            position={position}
            addSkillsPath={addSkillsPath}
            editable={editable}
          />
        )}
      </>
    )
  }
)
