import { Tag } from 'src/design-system'
import * as React from 'react'
import { Input } from 'components/atoms/input'
import * as MegaModal from '../../mega-modal'

type LabelInfoLineProps = {
  editable?: boolean
  invalid?: boolean
  labels: string[]
  onBlur: () => void
  onChange: (value: string | null) => void
  value: string | null
}

export const LabelInfoLine: React.VFC<LabelInfoLineProps> = (props) => {
  const { editable, invalid, labels, onBlur, onChange, value } = props

  if (!editable && labels.length === 0) return null

  return (
    <MegaModal.InfoLine
      label="Label"
      value={
        editable ? (
          <EditableValue
            invalid={invalid}
            labels={labels}
            onBlur={onBlur}
            onChange={onChange}
            value={value ? value.toString() : null}
          />
        ) : (
          <Tags className="flex flex-col gap-1" labels={labels} />
        )
      }
    />
  )
}

const EditableValue: React.VFC<{
  invalid?: boolean
  labels: string[]
  onBlur: () => void
  onChange: (value: string | null) => void
  value?: string | null
}> = (props) => {
  const { invalid, labels, onBlur, onChange, value } = props

  return (
    <Input
      className="-ml-4"
      forceShowPlaceholder={!!value}
      invalid={invalid}
      naked
      nonFocusedPlaceholder={<Tags className="flex gap-1" labels={labels} />}
      onBlur={onBlur}
      onChange={onChange}
      placeholder="Override labels"
      type="text"
      value={value}
    />
  )
}

const Tags: React.VFC<{ className?: string; labels: string[] }> = (props) => {
  const { className, labels } = props

  return (
    <div className={className}>
      {labels.map((label) => (
        <Tag key={label}>{label}</Tag>
      ))}
    </div>
  )
}
