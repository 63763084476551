import { Category } from 'store/modules/categories'
import { Position } from 'store/modules/positions'
import { Requirement } from 'store/modules/requirements'
import { store } from 'store/index'

type RequirementWithListPosition = {
  id: string
  listPosition: number
  requirement: Requirement
}

export class RequiredSkillsSectionVm {
  constructor(
    private outcomeState: Record<string, boolean> | null,
    private position: Position
  ) {}

  get hasRequirements() {
    return this.position.requirements.length > 0
  }

  get sortedCategoriesWithRequirements() {
    return this.sortedCategories.reduce<Record<string, Requirement[]>>(
      (acc, category) => {
        acc[category.name] = this.sortedCategoryRequirements(category)

        return acc
      },
      {}
    )
  }

  newIsViewing(isViewing: boolean, states: boolean[]) {
    if (isViewing && states.every((state) => !state)) return false
    if (!isViewing && states.every((state) => state)) return true

    return isViewing
  }

  newOutcomeState(open: boolean, requirementId?: string) {
    const newOutcomeState = this.outcomeState
      ? { ...this.outcomeState }
      : this.defaultOutcomeState

    if (requirementId) {
      newOutcomeState[requirementId] = open
    } else {
      Object.keys(newOutcomeState).forEach(
        (key) => (newOutcomeState[key] = open)
      )
    }

    return newOutcomeState
  }

  open(requirementId: string) {
    return this.outcomeState?.[requirementId] || false
  }

  outcomes(level: number, skillVariantId: string) {
    return store.outcomes.forLevelAndSkillVariant(level, skillVariantId)
  }

  skillLevelDescription(level: number, skillVariantId: string) {
    return store.skillLevels.forLevelAndSkillVariant(level, skillVariantId)
      ?.name
  }

  private get defaultOutcomeState() {
    return this.position.requirements.reduce<Record<string, boolean>>(
      (states, requirement) => {
        if (
          requirement?.skillVariant &&
          this.outcomes(requirement.level, requirement.skillVariant.id).length >
            0
        ) {
          states[requirement.id] = false
        }

        return states
      },
      {}
    )
  }

  private get sortedCategories() {
    return this.sortByListPosition(
      this.position.requirements.reduce<Category[]>(
        (categories, requirement) => {
          if (requirement?.frameworksSkill) {
            const frameworksSkill = requirement.frameworksSkill

            if (frameworksSkill) {
              const category =
                frameworksSkill.category || this.uncategorisedCategory

              if (
                category &&
                !categories.find((cat) => cat.id === category.id)
              ) {
                categories.push(category)
              }
            }
          }

          return categories
        },
        []
      )
    )
  }

  private get uncategorisedCategory(): Category {
    return Category.create({
      id: 'uncategorised',
      createdAt: new Date(),
      listPosition: 10000000,
      name: 'Uncategorised',
      updatedAt: new Date(),
    })
  }

  private sortByListPosition<T extends Category | RequirementWithListPosition>(
    objects: T[]
  ) {
    return objects.sort((a, b) => {
      if (a.listPosition === b.listPosition) {
        return a.id.localeCompare(b.id)
      }
      return a.listPosition - b.listPosition
    })
  }

  private sortedCategoryRequirements(category: Category) {
    const sorted = this.sortByListPosition(
      this.position.requirements.reduce<RequirementWithListPosition[]>(
        (requirements, requirement) => {
          if (requirement?.frameworksSkill) {
            const frameworksSkill = requirement.frameworksSkill

            if (
              frameworksSkill &&
              (frameworksSkill.category?.id === category.id ||
                (!frameworksSkill.category && category.id === 'uncategorised'))
            ) {
              requirements.push({
                id: requirement.id,
                listPosition: frameworksSkill.listPosition,
                requirement,
              })
            }
          }

          return requirements
        },
        []
      )
    )

    return sorted.map((requirement) => requirement.requirement)
  }
}
