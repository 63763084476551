import React from 'react'
import { Category } from 'store/modules/categories'
import { Framework } from 'store/modules/frameworks'
import { Position } from 'store/modules/positions'
import { SkillVariant } from 'store/modules/skill-variants'
import { Dropdown, useModalContext, Tooltip } from 'src/design-system'
import { SelectRequirementModalProps } from 'components/select-requirement-modal'
import { SELECT_REQUIREMENT_MODAL_ID } from 'components/select-requirement-modal/utils'
import { observer } from 'mobx-react-lite'
import { POSITION_MODAL_ID, PositionModalProps } from '../utils'
import { store } from 'store/index'
import { LoadingContent } from 'components/loading-content'
import { ArrowRight } from '@phosphor-icons/react'

export type AddSkillsDropdownProps = {
  position: Position
  addSkillsPath: string
  children: React.ReactNode
  styledTrigger?: boolean
}

export const AddSkillsDropdown = observer<AddSkillsDropdownProps>(
  ({ position, addSkillsPath, children, styledTrigger = false }) => {
    const [loading, setLoading] = React.useState(true)

    const { openModal } = useModalContext()

    const teamName = position.framework?.team?.name ?? 'Team'
    const framework = position.framework as Framework | undefined

    if (!framework) return null

    const { categories } = framework

    const filterSkills = (variants: SkillVariant[]) =>
      variants
        .filter((variant) => !position.skills.includes(variant.skill))
        .sort((a, b) => a.skill.name.localeCompare(b.skill.name))

    const map = new Map<Category | null, SkillVariant[]>()
    map.set(null, filterSkills(framework.uncategorisedSkills))

    const sections = categories.reduce((map, category) => {
      const skills = framework.categorySkills(category.id)
      map.set(category, filterSkills(skills))
      return map
    }, map)

    const onAddSkill = (skillVariant: SkillVariant) => {
      openModal<SelectRequirementModalProps>(SELECT_REQUIREMENT_MODAL_ID, {
        position,
        skill: skillVariant.skill,
        skillVariant,
        onSuccess: () => {
          openModal<PositionModalProps>(POSITION_MODAL_ID, {
            positionId: position.id,
          })
        },
      })
    }

    const onOpenChange = async (open: boolean) => {
      if (!open) return
      if (!loading) return

      await store.frameworks.fetchOne(framework.id, {
        include: [
          'frameworks_skills',
          'frameworks_skills.skill_variant',
          'frameworks_skills.skill_variant.skill',
        ],
      })

      setLoading(false)
    }

    const title = `Add Skills from the ${teamName} team`

    return (
      <Dropdown.Root align="left" onOpenChange={onOpenChange} modal={false}>
        <Tooltip content={title}>
          <Dropdown.Trigger
            className="text-green-600"
            showIcons={false}
            title={title}
            variant={styledTrigger ? 'styled' : 'unstyled'}
          >
            {children}
          </Dropdown.Trigger>
        </Tooltip>
        <Dropdown.Content className="max-h-[45vh] overflow-y-auto">
          <LoadingContent className="p-4" loading={loading}>
            {!loading && (
              <>
                <p
                  className="pointer-events-none font-bold px-3 py-2 mb-0"
                  key="title"
                >
                  {title}
                </p>
                {Array.from(sections.entries()).map(
                  ([category, skills]) =>
                    !!skills.length && (
                      <>
                        <p
                          key={category?.id}
                          className="pointer-events-none text-xs text-gray-400 px-3 pt-2 mb-0"
                        >
                          {category ? category.name : 'Uncategorised'}
                        </p>
                        {skills.map((variant) => (
                          <Dropdown.Button
                            key={variant.id}
                            onClick={() => onAddSkill(variant)}
                          >
                            {variant.skill.name}
                          </Dropdown.Button>
                        ))}
                      </>
                    )
                )}
                <Dropdown.Link
                  href={addSkillsPath}
                  className="text-gray-500 items-center flex gap-2"
                >
                  Add more Skills to team
                  <ArrowRight size={14} />
                </Dropdown.Link>
              </>
            )}
          </LoadingContent>
        </Dropdown.Content>
      </Dropdown.Root>
    )
  }
)
